import { api } from '@converse/headless';
import { CustomElement } from './element.js';
import { html } from 'lit';


export class ConverseBrandLogo extends CustomElement {

    render () { // eslint-disable-line class-methods-use-this
        const is_fullscreen = api.settings.get('view_mode') === 'fullscreen';
        return html`
            <a class="brand-heading" href="#0" target="_blank" rel="noopener">
                <span class="brand-name-wrapper ${is_fullscreen ? 'brand-name-wrapper--fullscreen' : ''}">
                    <span class="brand-name">
                        <span class="brand-name__text"><span class="subdued"></span></span>
                        <img src="dist/images/logo.png" style="width: 50%;">
                    </span>
                </span>
            </a>
        `;
    }
}

api.elements.define('converse-brand-logo', ConverseBrandLogo);
